.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    margin-top: .125em;
    
}

.secondDD:hover{
    cursor: pointer;
}
 
@media screen and (min-width: 769px) {
    .dropend:hover .dropdown-menu {
        position: absolute;
        top: 0%;
        left: 100%;
        border: none;

    }
}