ul.vertical-timeline {
    list-style-type: none;
    position       : relative;
  }
  
  ul.vertical-timeline:before {
    content   : ' ';
    background: #68B847;
    display   : inline-block;
    position  : absolute;
    left      : 29px;
    width     : 2px;
    height    : 100%;
    z-index   : -1;
  }
  
  ul.vertical-timeline>li {
    margin      : 20px 0;
    padding-left: 20px;
  }
  
  ul.vertical-timeline>li:before {
    content      : ' ';
    background   : white;
    display      : inline-block;
    position     : absolute;
    border-radius: 50%;
    border       : 3px solid #68B847;
    left         : 20px;
    width        : 20px;
    height       : 20px;
    z-index      : -1;
  }
  
  .text-h {
    color: #404040;
  }
  
  .bg-t {
    background-color: #F1F1F1;
  }
  