/* .bg-image{
   width: 150%;
   height: 380px; 
 
} */


@media only screen and (max-width: 765px) {
   .imgDiv{
    display: none;
   }
  }

  .main-area{
   height: 80vh;
  }