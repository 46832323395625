.f-content {
    font-size: 0.8rem;
}

.f-link:hover {
    cursor: pointer;
    text-decoration: underline;
    
}

img.logo-bottom {
    width : 10rem !important;
    height: auto !important;
  }